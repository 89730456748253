const rotateConnections = (connections, angle) => {
  // Initialize a new object to store the rotated connections
  const newConnections = {};

  // Safely check for the presence of each connection and rotate accordingly
  if (angle === 90) {
    if (connections.top) newConnections.right = { ...connections.top };
    if (connections.right) newConnections.bottom = { ...connections.right };
    if (connections.bottom) newConnections.left = { ...connections.bottom };
    if (connections.left) newConnections.top = { ...connections.left };
  } else if (angle === 180) {
    if (connections.top) newConnections.bottom = { ...connections.top };
    if (connections.right) newConnections.left = { ...connections.right };
    if (connections.bottom) newConnections.top = { ...connections.bottom };
    if (connections.left) newConnections.right = { ...connections.left };
  } else if (angle === 270) {
    if (connections.top) newConnections.left = { ...connections.top };
    if (connections.right) newConnections.top = { ...connections.right };
    if (connections.bottom) newConnections.right = { ...connections.bottom };
    if (connections.left) newConnections.bottom = { ...connections.left };
  } else {
    // If no rotation or invalid angle, return the original connections as-is
    return connections || {};
  }

  // Return the rotated connections object
  return newConnections;
};

export default rotateConnections;
