import straightWire from '../assets/straight-wire.svg';
import block from '../assets/block.svg';
import tWire from '../assets/t-wire.svg';
import star from '../assets/star-off.svg';
import cornerWire from '../assets/corner-wire.svg';

import redLightBulb from '../assets/red-light-bulb.svg';
import redLightBulbSchematic from '../assets/red-light-bulb-schematic.svg';
import yellowLightBulb from '../assets/yellow-light-bulb.svg';
import yellowLightBulbSchematic from '../assets/yellow-light-bulb-schematic.svg';
import greenLightBulb from '../assets/green-light-bulb.svg';
import greenLightBulbSchematic from '../assets/green-light-bulb-schematic.svg';

import battery from '../assets/battery.svg';
import batterySchematic from '../assets/battery-schematic.svg';

import mosfet from '../assets/mosfet.svg';
import mosfetSchematic from '../assets/mosfet-schematic.svg';

import switchOnImage from '../assets/comp-switch-on.svg';
import switchOffImage from '../assets/comp-switch-off.svg';
import switchOnImageSchematic from '../assets/comp-switch-on-schematic.svg';
import switchOffImageSchematic from '../assets/comp-switch-off-schematic.svg';

import resistor0 from '../assets/comp-resistor-0.svg';
import resistor1 from '../assets/comp-resistor-1.svg';
import resistor0Schematic from '../assets/comp-resistor-0-schematic.svg';
import resistor1Schematic from '../assets/comp-resistor-1-schematic.svg';
const resistorImages = [resistor0, resistor1]; 
const resistorSchematicImages = [resistor0Schematic, resistor1Schematic];


const componentDictionary = {
    'green-light-bulb': { svgPath: greenLightBulb, schematicSvgPath: greenLightBulbSchematic, connections: { 
      right: { active: true, pin: 'anode' }, 
      bottom: { active: true, pin: 'cathode' } 
     },
    resistanceValue: 40,
     },
    'red-light-bulb': { svgPath: redLightBulb, schematicSvgPath: redLightBulbSchematic, connections: { 
      right: { active: true, pin: 'anode' }, 
      bottom: { active: true, pin: 'cathode' } 
     },
    resistanceValue: 40,
   },
    'yellow-light-bulb': { svgPath: yellowLightBulb, schematicSvgPath: yellowLightBulbSchematic, connections: { 
      top: { active: true, pin: 'anode' }, 
      bottom: { active: true, pin: 'cathode' } 
     },
    resistanceValue: 40,
   },
    'battery': { svgPath: battery, schematicSvgPath: batterySchematic, connections: { 
      left: { active: true, pin: 'positive' }, 
      right: { active: true, pin: 'negative' } 
     } },
    'straight-wire': { svgPath: straightWire, connections: { top: { active: true }, bottom: { active: true } } },
    'star': { svgPath: star, connections: { left: { active: true }, right: { active: true } } },
    'block': { svgPath: block, connections: {} },
    't-wire': { svgPath: tWire, connections: { right: { active: true }, bottom: { active: true }, left: { active: true } } },
    'corner-wire': { svgPath: cornerWire, connections: { left: { active: true }, bottom: { active: true } } },
    'switch': {
      onConnections: { left: { active: true }, right: { active: true } },
      offConnections: {},
  
      initialize: (defaultState) => {
        const isOn = defaultState === 'ON';
        const connections = isOn ? componentDictionary['switch'].onConnections : componentDictionary['switch'].offConnections;
        const svgPath = isOn ? switchOnImage : switchOffImage;
        const schematicSvgPath = isOn ? switchOnImageSchematic : switchOffImageSchematic;
        
        return { svgPath,schematicSvgPath, connections, isOn }; // Return both the state and connections
      },
    },
    'resistor': {
      svgPath: resistor0, // Provide a default svgPath
      connections: { left: { active: true }, right: { active: true } },
      initialize: (id = 0) => {
        const svgPath = resistorImages[id % resistorImages.length];
        const schematicSvgPath = resistorSchematicImages[id % resistorSchematicImages.length];
        return {
          svgPath,
          schematicSvgPath,
          connections: { left: { active: true }, right: { active: true } },
        };
      },
      
    },
    'mosfet': {
        svgPath: mosfet,
        schematicSvgPath: mosfetSchematic,
        connections: {
          left: { active: true, pin: 'gate' },
          top: { active: true, pin: 'drain' },
          bottom: { active: true, pin: 'source' }
        },
      },
  };

  export default componentDictionary;