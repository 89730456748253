// src/components/MainHomePage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './MainHomePage.css'; // Import your CSS file
import HomePageImg from '../assets/HomePageImg.png'; // Import the image
import BearImg from '../assets/BearImg.png'; // Import the image
import { signInAnonymously,updateProfile  } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { useState } from 'react';

// Function to generate random guest names
const generateRandomGuestName = () => {
  const adjectives = [
    'Zappy', 'Sparky', 'Wired', 'Charged', 'Glitchy', 'Jumpy', 
    'Current', 'Volty', 'Circuit', 'Funky', 'Buzzy', 'Electro', 
    'Shiny', 'Quantum', 'Vibrant', 'Ohmic', 'Magnetic', 'Resisty', 
    'Capacitive', 'Diodey'
  ];

  const nouns = [
    'Resistor', 'Capacitor', 'Transistor', 'Diode', 'Wire', 'Node', 
    'LED', 'Inductor', 'Relay', 'Battery', 'PCB', 'Chip', 
    'Electron', 'Circuit', 'Amp', 'Volt', 'Switch', 
    'Coil', 'Spark','Cat'
  ];

  const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
  const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
  const randomNumber = Math.floor(Math.random() * 1000);

  return `${randomAdjective}${randomNoun}${randomNumber}`; // Example: ZappyResistor453
};


const MainHomePage = () => {
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();
  
  const handleGuestLogin = async () => {
    setLoading(true);  // Start loading
    try {
      // 1. Sign in anonymously
      const userCredential = await signInAnonymously(auth);
      const user = userCredential.user;
      const randomName = generateRandomGuestName();
  
      // 2. Set the user's displayName locally
      await updateProfile(user, { displayName: randomName });
  
      // 3. Write to Firestore
      await setDoc(doc(db, 'users', user.uid), {
        name: randomName,
        points: 0, // Initialize points for new users
        createdAt: new Date(), // Optional: timestamp for sorting later
      });
  
      console.log('Successfully created guest user:', randomName);
      navigate('/learn');
    } catch (error) {
      console.error('Error logging in as guest:', error);
    }finally {
      setLoading(false);  // Stop loading
    }
  };
  
  
  const handleLogin = () => {
    navigate('/login');
  };

  const handleSignUp = () => {
    navigate('/signup');
  };


  return (
    <>
    <div className='MainHomePage-Body'>
      {/* Header */}
      <div className="header-box">
        <h1>playmicro</h1>
      </div>

      {/* Main content wrapper */}
      <div className="main-homepage-wrapper">
        <div className="main-section">
            <div className="ImgWrapper">
                <img
                    src={HomePageImg} // Display banner image
                    alt="Main Circuit Banner"
                    className="banner-image"
                />
                <h2>A fun and accessible way to learn electronics</h2>
            </div>
          {/* Container for the text and button */}
          <div className="button-container">
              <h3>Try it out</h3>
              <div className="button-wrapper">
              <button className="guest-login-button" onClick={handleGuestLogin} disabled={loading}>
                {loading ? 'Loading...' : 'Guest Login'}
              </button>
                
              <button className="login-button" onClick={handleLogin}>
                Login
              </button>
              <button className="signup-button" onClick={handleSignUp}>
                Sign Up
              </button>
              </div>
            </div>
        </div>



        <div className="section">
            <div className="title-container">
                <h3>What is Playmicro?</h3>
                <p>Short overview</p>
            </div>
            <div className="content">
                <p>Playmicro is focused on making learning electronics accessible, fun and effective.</p>
                <p>This is achieved by breaking key principles down into small chunks that learners can engage with daily.</p>
                <p>By setting up the exercise like a puzzle game, learners are prompted to explore and be curious about how to achieve the set goal – with the game mechanics being electronic principles.</p>
                <p>Thus, through trial and error, curiosity and pattern recognition, learners pick up the core principles of circuits.</p>
            </div>
            <div className="section-image">
                <img src={BearImg} alt="Playmicro character" />
            </div>
        </div>
        {/* <div className="section">
            <div className="title-container">
                <h3>Partner with us!</h3>
                <p>Lets take on the employment gap</p>
            </div>
            <div className="content">
                <p>We are looking for schools, charities and company's to partner with to focus on 3 key areas:</p>
                <li>Schools: Help guide the content and improve student engagement. Lets work together to deploy this app into schools and get students into electronics</li>

                <li>Companies: Help drive this initiative by providing the backing and content to ensure we are teaching industry relevant content</li>

                <li>Charities: Support pushing this in front of schools and sponsors to help bring this initiative to life</li>

                <p>Get in touch by emailing:</p>
                <p style={{color:"#1FB6FF"}}>Jackdaly@playmicrocade.com</p>
            </div>
        </div> */}

      </div>
      </div>
    </>
  );
};

export default MainHomePage;